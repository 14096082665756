import React from 'react'
import { ListGroup, ListGroupItem,Badge } from "react-bootstrap";


const Lesson = ({node}) => {   
   

    return (
        <section className='mt-2'>
            <h3 className='paper-title'>{node.frontmatter.title}</h3>               
            <div>
                <ListGroup variant='flush'>
                    {
                       node.frontmatter.teaching.map(({lessonName, lessonType, lessonTime, semester, isSeminar}, index) => (    
                        <ListGroupItem key={"L_" + index} >
                            {lessonName} | {lessonType.join('/')} | {semester} | {lessonTime} | {isSeminar ? <Badge variant="primary">Seminar</Badge>  : ""}
                        </ListGroupItem>
                       )) 
                    }
                    
                </ListGroup>

            </div>            
        </section>
    )
}

export default Lesson
