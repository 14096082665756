import React from 'react'
import Layout from '../components/layout'
import Lesson from "../components/lesson";
import { Col, Row } from "react-bootstrap";
import SEO from "../components/seo";

export default function Teaching({ data }) {
  return (
    <Layout>
      <SEO title="Teaching"></SEO>
      <Row>
        <Col>
          <h2 className='text-capitilize'>Teaching</h2>
        </Col>
      </Row>
      <Row>
        <Col>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <Lesson node={node} key={"T_" + index}></Lesson>
            ))
            }
        </Col>
      </Row>
    </Layout>
  )
}


export const query = graphql`
query Schooles {
  allMarkdownRemark(filter: {frontmatter: {layout: {eq: "school"}}}, sort: {fields: frontmatter___date, order: DESC}) {
    edges {
      node {
        id
        frontmatter {
          title
          date
          teaching {
            lessonTime
            lessonType
            lessonName
            isSeminar
            semester
          }
        }
      }
    }
  }
}

`